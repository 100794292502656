<template>
  <div class="col-sm-12 col-md-12 col-xs-12">

      <div class="nav-scroller py-1 mb-4">

        <nav class="nav d-flex justify-content-between mb-5" id="navScroller">
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/dashboard'}" v-bind:to="'/dashboard'">Active</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/dashboard/ended'}" v-bind:to="'/dashboard/ended'">Ended</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/dashboard/winnings'}" v-bind:to="'/dashboard/winnings'">Winnings</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/dashboard/topup'}" v-bind:to="'/dashboard/topup'">Top-up</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/dashboard/transactions'}" v-bind:to="'/dashboard/transactions'">Transactions</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path ==  '/dashboard/update-details'}" v-bind:to="'/dashboard/update-details'">Personal Details</router-link>
          <router-link class="p-2 dashboard-page" v-bind:class="{'active-page':this.$route.path == '/dashboard/change-password'}" v-bind:to="'/dashboard/change-password'">Change Password</router-link>
          <router-link class="p-2 logout-link" v-bind:to="'/logout/'">Logout</router-link>
        </nav>

      </div>

<!-- 
    <div class="row mt-5">
      <div class="col-xs-12 col-sm-4 col-md-12">
        <a
          class="mb-2 show heading mt-5"
          style="padding: 0px"
          data-toggle="collapse"
          href="#myticketscollapse"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          My Entries
          <svg
            class="icon-toggle"
            xmlns="http://www.w3.org/2000/svg"
            width="10.905"
            height="11.858"
            viewBox="0 0 20.905 11.858"
          >
            <path
              id="Icon_awesome-caret-down"
              data-name="Icon awesome-caret-down"
              d="M2.2,13.5H20.292a1.4,1.4,0,0,1,.991,2.4l-9.042,9.049a1.409,1.409,0,0,1-1.99,0L1.209,15.9A1.4,1.4,0,0,1,2.2,13.5Z"
              transform="translate(-0.794 -13.5)"
            />
          </svg>
        </a>
        <div id="myticketscollapse" class="collapse show mt-2 mb-2">
          <div class="list-group">
            <router-link v-bind:to="'/dashboard'" v-bind:class="{'active mb-0':this.selected == 0}"
              >Active
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="32"
                height="32"
                viewBox="0 0 72 72"
              >
                <defs>
                  <filter
                    id="Ellipse_642"
                    x="0"
                    y="0"
                    width="72"
                    height="72"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  transform="matrix(1, 0, 0, 1, 0, 0)"
                  filter="url(#Ellipse_642)"
                >
                  <circle
                    id="Ellipse_642-2"
                    data-name="Ellipse 642"
                    cx="27"
                    cy="27"
                    r="27"
                    transform="translate(9 6)"
                    fill="#01bda5"
                    opacity="0.9"
                  />
                </g>
                <text
                  id="_2"
                  data-name="2"
                  transform="translate(26 7)"
                  fill="#fff"
                  font-size="35"
                  font-family="NirmalaUI-Bold, Nirmala UI"
                  font-weight="700"
                >
                  <tspan x="0" y="38">{{joinedActive}}</tspan>
                </text>
              </svg>
            </router-link>
            <router-link v-bind:to="'/dashboard/ended'" v-bind:class="{'active mb-0':this.selected == 1}"> Ended </router-link>
            <router-link v-bind:to="'/dashboard/winnings'" v-bind:class="{'active mb-0 mt-1':this.selected == 2}"> Winnings </router-link>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-12">
        <a
          class="mb-2 heading"
          style="padding: 0px"
          data-toggle="collapse"
          href="#mywalletcollapse"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          Wallet
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.905"
            height="11.858"
            viewBox="0 0 20.905 11.858"
          >
            <path
              id="Icon_awesome-caret-down"
              data-name="Icon awesome-caret-down"
              d="M2.2,13.5H20.292a1.4,1.4,0,0,1,.991,2.4l-9.042,9.049a1.409,1.409,0,0,1-1.99,0L1.209,15.9A1.4,1.4,0,0,1,2.2,13.5Z"
              transform="translate(-0.794 -13.5)"
            />
          </svg>
        </a>
        <div id="mywalletcollapse" class="collapse show">
          <div class="list-group mb-2">
            <router-link v-bind:to="'/dashboard/topup'" v-bind:class="{'active mb-0':this.selected == 3}"> 
            Top-Up 
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="32"
                height="32"
                viewBox="0 0 72 72"
              >
                <defs>
                  <filter
                    id="Ellipse_643"
                    x="0"
                    y="0"
                    width="72"
                    height="72"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  transform="matrix(1, 0, 0, 1, 0, 0)"
                  filter="url(#Ellipse_643)"
                >
                  <circle
                    id="Ellipse_643-2"
                    data-name="Ellipse 643"
                    cx="27"
                    cy="27"
                    r="27"
                    transform="translate(9 6)"
                    fill="#01bda5"
                    opacity="0.9"
                  />
                </g>
                <line
                  id="Line_11"
                  data-name="Line 11"
                  x2="28"
                  transform="translate(22.5 33.5)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="6"
                />
                <line
                  id="Line_12"
                  data-name="Line 12"
                  y2="28"
                  transform="translate(36.5 19.5)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="6"
                />
              </svg>
            </router-link>
            
            <router-link v-bind:to="'/dashboard/transactions'" v-bind:class="{'active mb-0':this.selected == 4}">Transactions</router-link>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-12">
        <a
          class="heading"
          style="padding: 0px"
          data-toggle="collapse"
          href="#mypersonalcollapse"
          role="button"
          aria-expanded="false"
        >
          Personal Details
          <svg
            class="icon-toggle"
            xmlns="http://www.w3.org/2000/svg"
            width="10.905"
            height="11.858"
            viewBox="0 0 20.905 11.858"
          >
            <path
              id="Icon_awesome-caret-down"
              data-name="Icon awesome-caret-down"
              d="M2.2,13.5H20.292a1.4,1.4,0,0,1,.991,2.4l-9.042,9.049a1.409,1.409,0,0,1-1.99,0L1.209,15.9A1.4,1.4,0,0,1,2.2,13.5Z"
              transform="translate(-0.794 -13.5)"
            />
          </svg>
        </a>
        <div id="mypersonalcollapse" class="collapse show mb-2">
          <div class="list-group">
            <router-link v-bind:to="'/dashboard/update-details'" v-bind:class="{'active mb-0':this.selected == 5}">Update Details</router-link>
            <router-link v-bind:to="'/dashboard/change-password'" v-bind:class="{'active mb-0':this.selected == 6}">Change Password</router-link>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <button type="button" class="btn btn-custom mt-3 w-50">Log out</button> -->
  </div>
</template>

<script>
import Api from '../../scripts/api'
export default {
  props: {
    selected:Number
  },
  data() {
    return {
      joinedActive: 0
    }
  },
  async created() {
    var result = await Api.competition.listUserActive();
    if(result.status == 200) {
      this.joinedActive = (await result.json()).length;
    }
  }
}
</script>